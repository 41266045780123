declare type SubscriptionRightsItem = {
    triggerEl: HTMLElement,
    valuesContainerEl: HTMLElement,
    targetEl: HTMLSelectElement,
    name: string,
}

/**
 * Met à jour l'affichage du prix des droits de souscription en fonction de la sélection
 * @param item
 * @version 0.0.1 - ##/##/#### by LM -
 *
 */
const updatePriceDisplay = (item: SubscriptionRightsItem): void => {
    const selectedIndex = item.targetEl.selectedIndex;

    // Afficher uniquement le conteneur de valeurs correspondant à l'index sélectionné
    item.valuesContainerEl.querySelectorAll('[data-index]').forEach((el) => {
        el.classList.add('hidden');
    });

    const selectedValueContainer = item.valuesContainerEl.querySelector(`[data-index="${selectedIndex}"]`);
    selectedValueContainer?.classList.remove('hidden');
};

/**
 * Synchronise les sélecteurs et les prix des droits de souscription
 * @param items
 * @param changedItem
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 12/03/2024
 */
const synchronizeSelectsAndPrices = (items: SubscriptionRightsItem[], changedItem: SubscriptionRightsItem) => {
    items
        .filter(item => (item.targetEl !== changedItem.targetEl))
        .filter(item => item.name === changedItem.name)
        .forEach(item => {
            item.targetEl.value = changedItem.targetEl.value;
            updatePriceDisplay(item)
        });
};

/**
 * Met à jour le Label en fonction du nombre de licences
 *
 * @param   item             Element contenant les infos sur les droit de souscriptions
 * @param   numberLicence    Nombre de licences
 * @param   identifier       Identifier pour faire correspondre le champ de saisie du nombre de licence avec la zone de souscriptions à modifier car elles sont chacune indépendante par produit
 * @version 0.0.1 - 19/03/2025 by LM -
 */
const updateLabel = (item: SubscriptionRightsItem, numberLicence: number, identifier: string) => {
    const label = item.triggerEl.querySelector('[data-label]') as HTMLElement | null;
    const defaultLabel = label.querySelector('[data-default-label]') as HTMLElement | null;
    const labelWithNumberLicence = label.querySelector(`[data-label-with-number-licence]`) as HTMLElement | null;
    const numberLicenceEl = labelWithNumberLicence?.querySelector('[data-number-licences]') as HTMLElement | null;

    // Si le nombre de licences est supérieur à 1, on affiche le label avec le nombre de licences
    // Sinon, on affiche le label par défaut
    if (labelWithNumberLicence && numberLicence > 1) {
        if (numberLicenceEl) {
            numberLicenceEl.innerText = numberLicence.toString();
        }

        defaultLabel.classList.add('hidden');
        labelWithNumberLicence.classList.remove('hidden');
    } else {
        defaultLabel.classList.remove('hidden');
        if (labelWithNumberLicence) {
            labelWithNumberLicence.classList.add('hidden');
        }
    }
}

/**
 * Initialise les gestionnaires d'évenements pour les droits de souscriptions
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 11/03/2024 by LM - Déplacement de logique dans la structure HTML
 * @version 0.0.3 - 20/03/2025 by LM - Gestion du label avec le nombre de licences
 */
const initSubscriptionRightsHandlers = (items): void => {
    items.forEach(item => {
        if(item.targetEl) {
            item.targetEl.addEventListener('change', () => {
                updatePriceDisplay(item);
                synchronizeSelectsAndPrices(items, item);
                document.dispatchEvent(new CustomEvent('subscription-rights-updated', {
                    detail: {
                        item
                    }
                }));
            });
        }
    });

    // lorsque le nombre de licences est mis à jour pour un produit
    document.addEventListener('number-licences-updated', (e: CustomEvent) => {
        e.stopPropagation();
        if(e.detail) {
            // On récupère l'élément correspondant à l'identifiant, pour mettre à jour le label contenant le nombre de licences
            const item : SubscriptionRightsItem = items.find((item: SubscriptionRightsItem) =>
                item.triggerEl.querySelector(`[data-label="${e.detail.identifier}"]`)
            );
            // Si l'élément existe, on met à jour le label
            if(item) {
                updateLabel(item, e.detail.value, e.detail.identifier);
            }
        }
    });
};

/**
 * Initialisation du module
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 11/03/2024 by LM
 */
export const initSubscriptionRights = (): void => {
    const items: SubscriptionRightsItem[] = Array.from(document.querySelectorAll('[data-subscription-rights]')).map(el => ({
        triggerEl: el as HTMLElement,
        valuesContainerEl: el.querySelector('[data-subscription-rights-values]') as HTMLElement,
        targetEl: el.querySelector('[data-subscription-rights-selector]') as HTMLSelectElement,
        name: el.getAttribute('data-subscription-rights')!,
    }));

    initSubscriptionRightsHandlers(items);
};


