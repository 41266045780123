import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import {initSweetAlert2} from "./SweetAlert";

let scrollPositionY; // POsition du scroll en cours

/**
 * Change l'état du loader du panier.
 * @param isEnabled - Indique si le loader doit être activé ou désactivé.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const toggleBasketLoader = (isEnabled : boolean) => {
    const loader = document.querySelector('[data-basket-loader]');
    loader?.classList.toggle('hidden', !isEnabled);
}

/**
 * Change l'état du bloc d'erreur du panier.
 * @param isEnabled Indique si le bloc doit être activé ou désactivé.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const toggleError = (isEnabled : boolean) => {
    const element = document.querySelector('[data-basket-error]');
    element?.classList.toggle('hidden', !isEnabled);
}

/**
 * Affiche / masque le contenu du panier
 * @param isEnabled Indique si le bloc doit être activé ou désactivé.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const toggleBasketContent = (isEnabled : boolean) => {
    const element = document.querySelector('[data-basket-content]');
    element?.classList.toggle('hidden', !isEnabled);
}

/**
 * Met à jour les compteurs de produit du panier. Si le panier contient des produits on compte le nombre de produits
 * sinon on affiche 0.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 12/12/2023 by LM - Isolation de la fonctionnalité
 * @version 0.0.3 - 12/12/2023 by LM - Gestion d'une erreur et de la suppression du panier local si le panier est corrompu
 */
const updateProductCount = () => {
    let productCount = 0
    try {
        const basket = JSON.parse(localStorage.getItem('basket') || '{}');

        // Parcourir les produits du panier et compter le nombre de produits
        basket.products.forEach((product: any) => {
            // Ajouter le nombre de produits enregistré dans product.quantity ou sinon 1
            productCount += parseInt(product.quantity) || 1
        })

    } catch (err) {
        //debug
        //console.debug('Erreur lors de la récupération du compteur du panier depuis le local storage', err);

        // Vider le panier local
        //todo : Tenter de récupérer le panier sur le serveur ?
        localStorage.removeItem('basket');
    }

    document.querySelectorAll('[data-basket-product-count]').forEach($targetEl => {
        $targetEl.textContent = productCount.toString();
    });
}

/**
 * Ajoute un produit au panier et met à jour le local storage.
 *
 * @param   form        Formulaire contenant les données du produit à ajouter au panier.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const addProductToCart = async (form: HTMLFormElement) : Promise<void> => {
    try {
        const basketForm = new FormData(form);
        const response = await axios.post('/basket/add-product-to-cart', basketForm, {
            headers: { 'Accept': 'application/json' }
        });

        localStorage.setItem('basket', JSON.stringify(response.data));
        updateProductCount();

        return Promise.resolve();
    } catch (err) {
        // debug
        //console.error(`Erreur lors de l'ajout d'un produit au panier`, err);
        return Promise.reject(err);
    }
}

/**
 * Met à jour les droits de souscription d'un produit.
 *
 * @param productId                     Id du produit à mettre à jour
 * @param subscriptionRightsKey         Clé du tableau des droits de souscription du produit (exemple : WD)
 * @param subscriptionRightsValue       Valeur à mettre à jour
 * @version 0.0.1 - 18/12/2023 by LM -
 */
const updateProductSubscriptionRights = async (productId: string, subscriptionRightsKey: string, subscriptionRightsValue: number|null) => {
    try {

        //console.log(productId, subscriptionRightsKey, subscriptionRightsValue)

        const response = await axios.put('/basket/update-product-subscription-rights-in-cart', {
            product_id: productId,
            subscription_rights_key: subscriptionRightsKey,
            subscription_rights_value: subscriptionRightsValue
        }, {
            headers: { 'Accept': 'application/json' },
        })

        localStorage.setItem('basket', JSON.stringify(response.data));
        updateProductCount();

        return Promise.resolve()
    } catch (err) {
        //debug
        //console.error('Erreur lors de la mise à jour des droits de souscription du produit', err)
        return Promise.reject(err)
    }
}

/**
 * Met à jour la quantité d'un produit dans le panier.
 *
 * @param   reference       Référence du produit à mettre à jour
 * @param   quantity        Nouvelle quantité du produit
 * @version 0.0.1 - 16/04/2024 by LM -
 */
const updateProductQuantity = async (reference: string, quantity: number) => {
    try {
        const response = await axios.put('/basket/update-product-quantity-in-cart', {
            reference: reference,
            quantity: quantity
        }, {
            headers: { 'Accept': 'application/json' },
        })

        localStorage.setItem('basket', JSON.stringify(response.data));
        updateProductCount();

        return Promise.resolve()
    } catch (err) {
        //debug
        //console.error('Erreur lors de la mise à jour de la quantité du produit', err)
        return Promise.reject(err)
    }
}

/**
 * Met à jour le code promo d'un panier
 *
 * @param   code    Code promo à mettre dans le panier (string|null)
 * @version 0.0.1 - 12/02/2025 by LM -
 */
const updatePromoCode = async (code: string|null) => {
    try {
        const response = await axios.put('/basket/update-promo-code-in-cart', {
            promo_code: code,
        }, {
            headers: { 'Accept': 'application/json' },
        })

        localStorage.setItem('basket', JSON.stringify(response.data));
        return Promise.resolve()

    } catch (err) {
        return Promise.reject(err)
    }
}

/**
 * Supprime un code promo du panier
 *
 * @param   code    Code promo à mettre dans le panier (string|null)
 * @version 0.0.1 - 18/02/2025 by LM -
 */
const removePromoCode = async (code: string|null) => {
    try {
        const response = await axios.delete('/basket/remove-promo-code-in-cart', {
            headers: { 'Accept': 'application/json' },
            data: {
                promo_code: code
            },
        })

        localStorage.setItem('basket', JSON.stringify(response.data));
        return Promise.resolve()

    } catch (err) {
        return Promise.reject(err)
    }
}

/**
 * Supprime un produit du panier et met à jour le local storage.
 *
 * @param   productId    Index du produit à supprimer du panier.
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const removeProductFromCart = async (productId: string) : Promise<void> => {
    try {
        const response = await axios.delete(`/basket/remove-product-from-cart/`, {
            headers: { 'Accept': 'application/json' },
            data: {
                product_id: productId
            },
        });

        localStorage.setItem('basket', JSON.stringify(response.data));
        updateProductCount();

        return Promise.resolve();
    } catch (err) {
        return Promise.reject(err);
        //debug
        //console.error('Erreur lors de la suppression du produit du panier', err)
    }
}

/**
 * Supprime la totalité du panier.
 *
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const clearCart = async () => {
    try {
        const response = await axios.delete('/basket/clear-cart', {
            headers: { 'Accept': 'application/json' }
        });
        if (response.statusText == "ok") {
            localStorage.removeItem('basket');
            updateProductCount();
        }
        return Promise.resolve();
    } catch (err) {
        //debug
        //console.error('Erreur lors du vidage du panier', err);
        return Promise.reject(err);
    }
}

/**
 * Récupère le contenu du panier côté serveur et met à jour la page
 *
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const updateHTMLBasketContent = async (billingCycle?: string|null) => {
    try {
        const response = billingCycle
            ? await axios.post('/basket/content', { 'billing_cycle': billingCycle })
            : await axios.post('/basket/content');

        if (response.status === 200) {
            document.querySelector('[data-basket-content]').innerHTML = response.data;
        }

        await initBasketHandlers(); // Réinitialise les gestionnaires d'événements du panier
        initSweetAlert2(); // Réinitialise les alertes sweetalert2
    } catch (err) {
        //debug
        //console.error("Erreur lors de la mise à jour de l'affichage du contenu du panier", err)
    }
}

/**
 * Synchronise le panier enregistré dans le local storage s'il existe, avec le serveur
 * et met à jour le local storage avec le panier à jour.
 * Si le panier n'existe pas dans le local storage, cette fonction ne fait rien.
 * Il faut passer une clé "basket" contenant le json complet du panier au point d'entrée /basket/store en POST
 *
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.1 - 12/12/2023 by LM - Isolation de la fonctionnalité
 */
const synchronizeBasketToServerFromLocalStorage = async () => {
    const basket = localStorage.getItem('basket');
    if (basket) {
        try {
            await axios.post('/basket/store', { basket }, {
                headers: { 'Accept': 'application/json' }
            });

            return Promise.resolve()
        } catch (err) {
            //debug
            //console.error('Erreur lors de la synchronisation du panier', err)
            return Promise.reject(err)
        }
    }
}

/**
 * Synchronise le panier enregistré sur le serveur avec le local storage.
 * Si le panier n'existe pas sur le serveur, cette fonction ne fait rien.
 */
const synchronizeBasketToLocalStorageFromServer = async () => {
    try {
        const response = await axios.get('/basket/stored')
        if (response.data) {
            localStorage.setItem('basket', JSON.stringify(response.data));
        }
        return Promise.resolve()
    } catch (err) {
        //debug
        //console.error('Erreur lors de la synchronisation du panier', err)
        return Promise.reject(err)
    }
}

/**
 * Initialise l'accordéon pour afficher la zone de droits de souscription
 *
 * @version 0.0.1 - 14/05/2023 by LM -
 */
const initSubscriptionRightsAccordion = () => {
    const accordionEl = document.querySelector('#accordion-subscription-rights');
    if(accordionEl) {
        const accordionItems: AccordionItem[] = [
            {
                id: 'accordion-subscription-rights-heading-1',
                triggerEl: document.querySelector('#accordion-subscription-rights-heading-1'),
                targetEl: document.querySelector('#accordion-subscription-rights-body-1'),
                active: false
            }
        ]

        const options: AccordionOptions = {
            alwaysOpen: true,
            activeClasses: 'text-blue-500',
            inactiveClasses: 'text-blue-600',
            onOpen: (item) => {
                // Ajouter d'un paramètre dans l'url pour indiquer que la section est ouverte
                const url = new URL(window.location.href);
                url.searchParams.set('subscription-rights-opened', '1');
                window.history.pushState({}, '', url.toString());
            },
            onClose: (item) =>  {
                // Retirer unqieuement le paramètre 'subscription-rights' de l'url pour indiquer que la section est fermée
                const url = new URL(window.location.href);
                url.searchParams.delete('subscription-rights-opened');
                window.history.pushState({}, '', url.toString());
            },
        }

        const instanceOptions: InstanceOptions = {
            id: 'accordion-subscription-rights',
            override: true
        };

        const accordion: AccordionInterface = new Accordion(accordionEl, accordionItems, options, instanceOptions);

        // Si un paramètre 'subscription-rights' est présent dans l'url, ouvrir la section
        const url = new URL(window.location.href);
        if(url.searchParams.get('subscription-rights-opened')) {
            accordion.open('accordion-subscription-rights-heading-1');
        }
    }
}

/**
 * Sauvegarde la position du scroll en Y
 *
 * @param y
 * @version 0.0.1 - 02/10/2024 by LM -
 */
const saveScrollPositionY = (y: number|null = null) => {
    scrollPositionY = y || window.scrollY || document.documentElement.scrollTop;
}

/**
 * Restaure la position du scroll en Y
 *
 * @version 0.0.1 - 02/10/2024 by LM -
 */
const restoreScrollPositionY = () => {
    window.scrollTo(0, scrollPositionY);
}

/**
 * Initialise les gestionnaires d'événements du panier.
 *
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 12/12/2023 by LM - Isolation de la fonctionnalité
 * @version 0.0.3 - 09/07/2024 by LM - Ajout des paramètres dans les urls de redirections
 */
const initBasketHandlers = async () => {
    // Ajoute le produit au panier et redirige sur la page du panier

    // @todo afficher une erreur dans une modal en cas d'erreur ?
    document.querySelectorAll('[data-basket-add-product-to-cart-and-go-basket]')
        .forEach((button : HTMLButtonElement) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                addProductToCart(button.form)
                    .then(() => {
                        window.location.href = window.__getHost() + '/basket' + window.location.search
                    })
            })
        })

    // Ajoute le produit et ferme la fenêtre modal s'il y en a une
    // @todo afficher une erreur dans une modal en cas d'erreur ?
    document.querySelectorAll('[data-basket-add-product-to-cart]')
        .forEach((button : HTMLButtonElement) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                addProductToCart(button.form)
                    .then(() => {
                        const modals = document.querySelectorAll('[data-modal-hide]');
                        modals.forEach(modal => {
                            modal.dispatchEvent(new Event('click'));
                        })
                    })
            })
        })

    // Écouteur d'événement pour vider la totalité du panier
    // document.querySelectorAll('[data-basket-clear-cart]')
    //     .forEach((button : HTMLButtonElement) => {
    //         button.addEventListener('click', async (e) => {
    //             e.preventDefault()
    //
    //             toggleBasketContent(false);
    //             toggleBasketLoader(true);
    //
    //             try {
    //                 await clearCart();
    //                 await updateHTMLBasketContent();
    //                 toggleBasketContent(true);
    //             } catch (error) {
    //                 //console.error('Erreur lors de la suppression du panier', error);
    //                 toggleBasketContent(false)
    //                 toggleError(true);
    //             } finally {
    //                 toggleBasketLoader(false);
    //             }
    //         });
    //     })

    // Écouteur d'événement pour supprimer un produit du panier
    document.querySelectorAll('[data-basket-remove-product-from-cart]')
        .forEach((form : HTMLFormElement) => {
            form.addEventListener('submit',  async (e) => {
                e.preventDefault()

                toggleBasketContent(false);
                toggleBasketLoader(true);

                try {
                    await removeProductFromCart(form.elements['product_id'].value);
                    await updateHTMLBasketContent();
                    toggleBasketContent(true);
                } catch (error) {
                    toggleBasketContent(false)
                    toggleError(true);
                } finally {
                    toggleBasketLoader(false);
                    restoreScrollPositionY();
                }
            })
        })

    // Écouteur d'événement pour mettre à jour les droits de souscriptions
    document.querySelectorAll('[data-basket-subscription-rights-select]').forEach((select : HTMLSelectElement) => {
        select.addEventListener('change', async (e) => {
            e.preventDefault()

            saveScrollPositionY()
            toggleBasketContent(false);
            toggleBasketLoader(true);

            const productId = select.dataset.basketProductId;
            const subscriptionRightsKey = select.dataset.basketSubscriptionRightsKey;
            const subscriptionRightsValue = select.value ? parseInt(select.value) : null;

            try {
                await updateProductSubscriptionRights(productId, subscriptionRightsKey, subscriptionRightsValue);
                await updateHTMLBasketContent();
                toggleBasketContent(true);
            } catch (error) {
                toggleBasketContent(false)
                toggleError(true);
            } finally {
                toggleBasketLoader(false);
                restoreScrollPositionY();
            }
        })
    })


    // Écouteur d'événement pour mettre à jour la quantité d'un produit
    document.querySelectorAll('[data-basket-product-quantity]').forEach((input : HTMLInputElement) => {
        input.addEventListener('input', _.debounce(async (e) => {
                e.preventDefault()

                saveScrollPositionY()
                toggleBasketContent(false);
                toggleBasketLoader(true);

                const reference = input.dataset.basketProductReference;
                const quantity = parseInt(input.value);

                try {
                    await updateProductQuantity(reference, quantity);
                    await updateHTMLBasketContent();
                    toggleBasketContent(true);
                } catch (error) {
                    toggleBasketContent(false)
                    toggleError(true);
                } finally {
                    toggleBasketLoader(false);
                    restoreScrollPositionY();
                }
            }, 500)
        )
    })

    // Application d'un code promo au niveau du panier
    document.querySelectorAll('[data-basket-form-promo-code]').forEach((form: HTMLFormElement) => {
        form.addEventListener('submit', async(e) => {
            e.preventDefault();

            const data: any = new FormData(form)

            saveScrollPositionY()
            toggleBasketContent(false);
            toggleBasketLoader(true);

            try {
                await updatePromoCode(data.get('promo_code') ?? null);
                await updateHTMLBasketContent();
                toggleBasketContent(true);
            } catch (error) {
                toggleBasketContent(false)
                toggleError(true);
            } finally {
                toggleBasketLoader(false);
                restoreScrollPositionY();
            }
        })
    })

    // Suppression d'un code promo du panier
    document.querySelectorAll('[data-basket-btn-clear-promo-code]').forEach(item => {
        item.addEventListener('click', async (e) => {
            e.preventDefault()

            const promoCode = item.getAttribute('data-basket-btn-clear-promo-code')

            saveScrollPositionY()
            toggleBasketContent(false);
            toggleBasketLoader(true);

            try {
                await removePromoCode(promoCode);
                await updateHTMLBasketContent();
                toggleBasketContent(true);
            } catch (error) {
                toggleBasketContent(false)
                toggleError(true);
            } finally {
                toggleBasketLoader(false);
                restoreScrollPositionY();
            }
        })
    })

    // Annuler un event listener précédent pour éviter les doublons
    const selectBillingCycle: HTMLSelectElement = document.querySelector('select[name="billing_cycle"]');
    if(selectBillingCycle) {
        selectBillingCycle.addEventListener('change', _.debounce(async (e) => {
            saveScrollPositionY()
            toggleBasketContent(false);
            toggleBasketLoader(true);

            try {
                await updateHTMLBasketContent(e.target.value);
                toggleBasketContent(true);
            } catch (error) {
                toggleBasketContent(false)
                toggleError(true);
            } finally {
                toggleBasketLoader(false);
                restoreScrollPositionY();
            }
        }))
    }

    initSubscriptionRightsAccordion()
}

/**
 * Initialise le panier.
 */
export const initBasket = async () => {
    await initBasketHandlers() // Initialise les gestionnaires d'événements du panier

    // Si le cookie de session "synchronizeBasketToServerFromLocalStorage" n'existe pas
    // synchronise le panier enregistré dans le local storage avec le serveur
    // if(!Cookies.get('synchronizeBasketToServerFromLocalStorage')) {
    //     await synchronizeBasketToServerFromLocalStorage()
    //     updateProductCount() // Met à jour les compteurs de produit du panier
    //     Cookies.set('synchronizeBasketToServerFromLocalStorage', true);
    // }
}
