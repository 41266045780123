import Cookies from 'js-cookie'
import {formatPrice} from "./Money";

/**
 * Met à jour le nombre de licences dans l'input du composant.
 *
 * @param $targetEl Element cible (l'input du composant courant)
 * @param numberLicences Nombre de licences à insérer dans l'input
 * @version 0.0.1 - 16/05/2024 by LM -
 * @version 0.0.2 - 19/03/2025 by LM - Émet un événement pour informer les autres composants du changement
 */
export const setNumberLicences = ($targetEl: HTMLInputElement, numberLicences: number, $identifier: string) => {
    if(numberLicences < 1) {
        numberLicences = 1;
    }

    document.dispatchEvent(new CustomEvent('number-licences-updated', {
        detail: {
            identifier: $identifier,
            value: numberLicences
        }
    }));

    $targetEl.value = numberLicences.toString();
}

/**
 * Retourne le nombre de licence enregistré dans l'input pour le produit
 *
 * @param $targetEl
 */
export const getNumberLicences = ($targetEl: HTMLInputElement) => {
    return parseInt($targetEl.value);
}

/**
 * Met à jour tous les champs de nombre de licences
 */
export const updateAllInput = ($identifier: string, numberLicence: number) => {
    document.querySelectorAll(`[data-product-number-licences="${$identifier}"] [data-product-number-licences-input]`)
        .forEach((input : HTMLInputElement) => input.value = numberLicence.toString());
};


/**
 * Met à jour le prix en fonction du nombre de licences
 * @version 0.0.1 - 11/03/2024 by LM -
 */
const updatePrice = ($identifier: string, numberLicence: number) => {
    //Met à jour tous les éléments ayant un attribut data-price en fonction du nombre de licence
    document.querySelectorAll(`[data-product-price="${$identifier}"]`).forEach((el: HTMLElement) => {
        el.querySelectorAll('[data-price]').forEach((priceEl: HTMLElement) => {
            const price = parseInt(priceEl.getAttribute('data-price') || '0');
            priceEl.innerText = formatPrice(price).format((price * numberLicence) / 100);
        });
    });
}

/**
 * Initialise les gestionnaires d'événements pour le nombre de licences
 *
 * @param   $triggerEl      Element déclencheur
 * @param   $targetEl       Element cible
 * @param   $identifier     Identifiant du composant qui permet de répercuter les changements
 * @version 0.0.1 - 16/05/2024 by LM -
 */
const initNumberLicencesHandlers = ($triggerEl : HTMLElement, $targetEl: HTMLInputElement, $identifier: string) => {
    // Lorsque l'utilisateur change directement la valeur dans le champ
    $targetEl.addEventListener('change', (e : Event) => {
        //@ts-ignore
        setNumberLicences($targetEl, e.target.value, $identifier);
        updatePrice($identifier, getNumberLicences($targetEl));
        updateAllInput($identifier, getNumberLicences($targetEl));
    });

    // Lorsque l'utilisateur clique sur le bouton +
    const buttonsIncrement = $triggerEl.querySelectorAll('[data-product-number-licences-button-increment]');
    buttonsIncrement.forEach((buttonIncrement) => {
        buttonIncrement.addEventListener('click', () => {
            setNumberLicences($targetEl, getNumberLicences($targetEl) + 1, $identifier);
            updatePrice($identifier, getNumberLicences($targetEl))
            updateAllInput($identifier, getNumberLicences($targetEl));
        });
    })

    // Lorsque l'utilisateur clique sur le bouton -
    const buttonsDecrement = $triggerEl.querySelectorAll('[data-product-number-licences-button-decrement]');
    buttonsDecrement.forEach((buttonDecrement) => {
        buttonDecrement.addEventListener('click', () => {
            setNumberLicences($targetEl, getNumberLicences($targetEl) - 1,$identifier);
            updatePrice($identifier, getNumberLicences($targetEl))
            updateAllInput($identifier, getNumberLicences($targetEl));
        });
    })
};

/**
 * Initialisation des fonctionnalités pour le nombre de licences
 *
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 16/05/2024 by LM - Le nombre de licences est maintenant par produit et non global à la page (demande JAE) :msg bal id : 19015275
 */
export const initNumberLicences = () => {
    document.querySelectorAll('[data-product-number-licences]').forEach(($triggerEl: HTMLElement) => {
        const $targetEl = $triggerEl.querySelector('[data-product-number-licences-input]') as HTMLInputElement;
        const $identifier = $triggerEl.getAttribute('data-product-number-licences')
        if($targetEl) {
            initNumberLicencesHandlers($triggerEl, $targetEl, $identifier)
        }
    });
}
